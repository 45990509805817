@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  * {
    @apply border-border;
    @apply focus-visible:outline-ring;
  }

  body {
    font-family: var(--body-font-family), var(--body-font-type);
    font-size: calc(var(--body-base-size) * 1px);
    line-height: var(--body-line-height);
    letter-spacing: calc(var(--body-letter-spacing) * 1px);
    -webkit-font-smoothing: var(--body-font-webkit-font-smoothing);
    -moz-osx-font-smoothing: var(--body-font-moz-osx-font-smoothing);
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: var(--heading-font-family), var(--heading-font-type);
    letter-spacing: calc(var(--heading-letter-spacing) * 1px);
    line-height: var(--heading-line-height);
    text-transform: var(--heading-capitalize);
    -webkit-font-smoothing: var(--heading-font-webkit-font-smoothing);
    -moz-osx-font-smoothing: var(--heading-font-moz-osx-font-smoothing);
  }

  .text-editor h1 {
    @apply text-h1 lg:text-h1-lg;
  }

  .text-editor h2 {
    @apply text-h2 lg:text-h2-lg;
  }

  .text-editor h3 {
    @apply text-h3 lg:text-h3-lg;
  }

  .text-editor h4 {
    @apply text-h4 lg:text-h4-lg;
  }

  .text-editor h5 {
    @apply text-h5 lg:text-h5-lg;
  }

  .text-editor h6 {
    @apply text-h6 lg:text-h6-lg;
  }

  .text-editor {
    ul,
    ol {
      @apply m-0;
      @apply pl-2;
      @apply list-inside;
      li {
        @apply mb-1.5;
      }
    }
  }

  .text-editor ul {
    @apply list-disc;
  }

  .text-editor ol {
    @apply list-decimal;
  }

  .text-editor blockquote {
    @apply italic;
  }
}

.swiper-product-carousel .swiper-pagination {
  --swiper-pagination-bottom: 40px;
  @apply pl-10 text-left lg:pl-20;
}

@media (min-width: 1024px) {
  .swiper-product-carousel .swiper-pagination {
    --swiper-pagination-bottom: 80px;
  }
}

/* Cards Animation CSS for blog cards and other carousel cards */
.swiper .clip-path,
.clip-path-wrapper .clip-path {
  border-radius: 0;
  transition: all 400ms;
  @apply group-hover:scale-90; /* @todo fix hover scale down effect */
}

.swiper-slide:hover .clip-path,
.clip-path-wrapper:hover .clip-path {
  border-radius: 50%;
  aspect-ratio: 1;
  object-fit: cover;
  @apply scale-90;
}

.swiper .clip-path-bottom-right,
.clip-path-bottom-right-wrapper .clip-path-bottom-right {
  border-radius: 0;
  transition: all 400ms;
}

.swiper-slide:hover .clip-path-bottom-right {
  border-radius: 0 0 60px 0;
}

.clip-path-bottom-right-wrapper:hover .clip-path-bottom-right {
  border-radius: 0 0 120px 0;
}

/* Arrow Animation CSS for arrows on carousel */
.arrow-left-animation,
.arrow-right-animation {
  transition: all 400ms;
}

.arrow-left-animation:hover {
  transform: translateX(-8px);
}

.arrow-right-animation:hover {
  transform: translateX(8px);
}

/* Bounce Animation CSS for FAQs and Social icons */
.accordion-header[data-state='closed']:hover .bounce-animation,
.social-icon:hover .bounce-animation {
  animation: bounce 0.4s ease-in-out;
}

@keyframes bounce {
  50% {
    transform: translateY(-0.25rem);
  }
  75% {
    transform: translateY(0);
  }
  85% {
    transform: translateY(0.0625rem);
  }
  100% {
    transform: translateY(0);
  }
}

.hero,
.background-image {
  height: 66.6666667vw;
}

/* Slide In Animation for all sections CSS */
@media (prefers-reduced-motion: no-preference) {
  .scroll-trigger.animate--slide-in {
    opacity: 0.01;
  }

  .scroll-trigger.animate--slide-in {
    transform: translateY(2rem);
  }

  .scroll-trigger:not(.scroll-trigger--offscreen).animate--slide-in {
    animation: slideIn 600ms cubic-bezier(0, 0, 0.3, 1) forwards;
    animation-delay: 0s;
  }

  .scroll-trigger:not(.scroll-trigger--offscreen).scroll-trigger--cancel {
    opacity: 1;
    animation: none;
    transition: none;
    transform: none;
  }

  @keyframes slideIn {
    from {
      transform: translateY(2rem);
      opacity: 0.01;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }
}

/* Hero Circle Progress bar Animation CSS */
.semi-circle {
  position: relative;
  bottom: 20vh;
  width: 100vh;
  height: 100vh;
  border-radius: 50%;
  transform-origin: center top;
  transition: all 0.2s ease-out;
  margin: 0 auto;
  z-index: -1;
}

@media (max-width: 992px) {
  .hero,
  .background-image {
    height: 100vh;
  }
  .semi-circle {
    left: calc(100vw / 4);
  }
}

/* section */
.section.bg- + .t-light-bg,
.section.t-cold-bg + .t-cold-bg,
.section.t-warm-bg + .t-warm-bg,
.section.t-dark-bg + .t-dark-bg {
  padding-top: 0 !important;
}

.video-section video {
  @apply object-cover;
}

/* brand shape svg data image */

.brand-shape {
  position: relative;
}

.brand-shape:after {
  content: ' ';
  display: block;
  position: absolute;
  width: 157px;
  height: 56px;
  background-image: url('data:image/svg+xml,%3Csvg xmlns%3D%22http%3A//www.w3.org/2000/svg%22 width%3D%22138%22 height%3D%2256%22 fill%3D%22none%22%3E%3Cg clip-path%3D%22url(%23a)%22%3E%3Cpath fill%3D%22%23FCFCFC%22 d%3D%22M54.571 28.934C33.3 28.934 14.174 18.41 1.909 0H-30c15.2 33.467 48.212 55.7 83.237 55.7 35.025 0 68.963-21.737 84.076-55.7h-30.038a61.611 61.611 0 0 1-52.704 28.934Z%22/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id%3D%22a%22%3E%3Cpath fill%3D%22%23fff%22 d%3D%22M-30 0h167.313v55.702H-30z%22/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.swiper-slide {
  position: relative; /* Ensures the pseudo-element positions relative to the slide */
  overflow: hidden; /* Prevents content from spilling out of the slide */
}

.brand-shape-center:after {
  content: ' ';
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 968px;
  height: 323px;
  background-image: url('../assets/brand.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  transform: translateX(-50%);
  pointer-events: none;
}

.brand-shape-left:after {
  content: ' ';
  position: absolute;
  bottom: 0;
  right: 0;
  width: 968px;
  height: 323px;
  background-image: url('../assets/brand.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  pointer-events: none;
}

.brand-shape-right:after {
  content: ' ';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 968px;
  height: 323px;
  background-image: url('../assets/brand.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  pointer-events: none;
}

/* for screens larger than 1024px */
@media (min-width: 1024px) {
  .brand-shape-center:after {
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .brand-shape-left:after {
    bottom: 50%;
    left: -25%;
    right: auto;
    transform: translateY(50%);
  }

  .brand-shape-right:after {
    bottom: 50%;
    right: -25%;
    left: auto;
    transform: translateY(50%);
  }
}

.text-highlight::after {
  content: '';
  position: absolute;
  height: 5px;
  left: 0;
  bottom: -5px;
  width: 0;
  background: currentColor;
  transition: width 0.4s ease;
}

.text-highlight:hover::after {
  width: 100%;
}
